.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
}

img{
    width: 100px;
}
.twitter{
    color: black;
}