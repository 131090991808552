@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCF1E9;  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  

}
.copy{
  display: grid;
  place-items: center;
}
// h1{
//   font-size: 10vw;
//   margin: 0;
//   font-weight: 900;
//   letter-spacing: 5px;
//   background: radial-gradient(circle, #9796f0, #E8CBC0, #FFC399);
//   -webkit-background-clip: text;
//   color: transparent;
//   background-size: 200%;
//   animation: gradient 2s infinite alternate;
  
// }

// @keyframes gradient {
//   0% {
//     background-position: 0%
//   }
//   100% {
//     background-position: 100%
//   }
// }

// h6{
//   margin: 0;
// }
// p{
//   font-size: 20px;
// }
