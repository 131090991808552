.root {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 5rem;
    margin-bottom: 0.125em;

}

.arrow {
    padding: 4em, 0;
}

.copy {
    margin-top: 2em;
    max-width: 80vw;
    text-align: center;
}