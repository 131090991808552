.root {
    display: flex;
    width: 80vw;
    list-style: none;
    background-color: white;
    margin: 2em 0;
    padding: 2em;
    border-radius: 7px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    max-width: 80vw;
}

.logoContainer {
    display: grid;
    place-content: center;
    width: 10%;
}

.companyLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: solid 1px lightgray;
    padding: 0.2em;
    width: 4em;
    height: 4em;
    border-radius: 100%;
}

.logo {
    width: 100%;
    
}

.noLogo {
    background-color: #F57D39;
    width: 5em;
    height: 5em;
    border-radius: 100%;
}

.infoContainer {
    width: 75%;
    padding-left: 3em;

}

.tagsContainer {
    display: none;
    // align-items: center;
    // width: 45%;
}

.tags {
    display: flex;
}

.tag {
    list-style: none;
    padding: 0.5em;
    width: fit-content;
    margin: 0 0.6em;
    background-color: black;
    color: white;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.new {
    background-color: #F57D39;
    color: white;
    padding: 0.2em 0.8em;
    margin: 0 1em;
    width: 100%;
    border-radius: 20px;
    font-size: 0.7rem;
    letter-spacing: 0.05em;
}

.applyLink {
    text-decoration: none;
    background-color: #F8F7F7;
    color: rgb(34, 33, 33);
    border: solid rgb(209, 177, 157) 1px;
    padding: 0.4em 1.2em;
    border-radius: 50px;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: bold;
}